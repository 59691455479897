/*
 * @Description: 
 * @Date: 2022-12-14 16:13:57
 * @LastEditors: xiaopang
 * @LastEditTime: 2025-03-19 17:15:25
 */
// const path = 'http://172.30.88.161:8867'
// const path = '/uploadFile'
import paths from '@/api/baseUrl' // 后台服务地址

let path = paths.path().filePath

export default {
  // uploadFile: 'http://172.30.88.161:8867/filesystem/directories/root',
  uploadFile: path + '/filesystem/directories/root',
  getProxyScript: path + '/filesystem/directories/root/scripts',
  downloadProxyFile: path + `/filesystem/files/root`, // 文件下载
  editFileBtOnly: path + `/edit/filesystem/files/root`
}